.text-cont-left,
.text-cont-right,
.text-cont-center {
  max-width: 190px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-cont-left {
  margin: 0 0 0 0;
}
.text-cont-right {
  margin: 0 0 0 auto;
}
.text-cont-center {
  margin: 0 auto;
}
